import React from 'react'
import { Link } from 'gatsby'

import { Document, Page } from 'react-pdf'
import erickhcv from '../documents/CV2019.pdf'
import "../components/PDFViewer.css"
import throttle from 'lodash.throttle';




export default class Test extends React.Component {
  /*state = {
   numPages: null,
   width:null,
   scale: null,
 }*/


 onDocumentLoadSuccess = (document) => {
   this.setPDFWidth()
   const { numPages } = document;
   this.setState({
     numPages,
   });
 };

 constructor(props){
   super(props);
   this.state= {
     numPages: null,
     width:null,
     scale: null,
   }
   this.setPDFWidth = this.setPDFWidth.bind(this);
   this.throttledSetPDFWidth = throttle(this.setPDFWidth, 500);
 }

 componentDidMount(){
   this.setPDFWidth()
   this.scale = 1.3;
   window.addEventListener('resize', this.throttledSetPDFWidth, false)

 }

 componentDidUpdate(){

 }

 componentWillUnmount() {
    window.removeEventListener('resize', this.throttledSetPDFWidth,false )
  }

 setPDFWidth() {
   var width  = this.divElement.clientWidth;
   var height = this.divElement.clientHeight;

   if (width < 690){
     var nw = (width*1.3)/690;//this.scale - 0.1
     this.scale = nw
     console.log("reduce size", this.scale);
   }else{
     this.scale = 1.3
     //if 690 - 1.3 ? x
   }
   this.setState({ width: width })
  console.log("width:", width, height);
  }

 render() {
   const { numPages, width, scale } = this.state;

   return (
     <div className="pdfViewer" ref={ (divElement) => this.divElement = divElement}>
     <Document className="pdfDocument"
       file={erickhcv}
       onLoadSuccess={this.onDocumentLoadSuccess}
     >
       {Array.from(
         new Array(numPages),
         (el, index) => (
           <Page className="pdfPage"
             key={`page_${index + 1}`}
             pageNumber={index + 1}
             renderInteractiveForms={true}
             scale={this.scale}
           />
         ),
       )}
     </Document>
     </div>
   );
 }
}
